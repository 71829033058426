$( document ).ready(function() {

  var observer = lozad();
  observer.observe();

  var isValid;
  var isEmailValid;
  var errorMsg = document.createElement('div');

  errorMsg.innerHTML = '<div class="alert__error">Please fill in the required fields.</div>';

  $('form input').on('click', function(e){
    $(this).css('border', '');
    (errorMsg).remove();
    isEmailValid = false;
  })

  function validate(optinForm) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    var emailField = $(optinForm).find("#inf_field_Email");
    var emailVal = $(emailField).val();

    if (emailVal == 'undefined' || emailVal == '') {
      isEmailValid = false;
      $(emailField).css('border', '3px solid red');
    } else {
      isEmailValid = emailReg.test(emailVal);
      if (isEmailValid) {
        isEmailValid = true;
        return true
      } else {
        $(emailField).css('border', '3px solid red');
        form.appendChild(errorMsg);
        setTimeout(function() {
          (errorMsg).remove();
        }, 5000);
        isEmailValid = false;
        return false;
      }
    }
  }

  $('.hero__text .btn-submit').click(function(e){
    e.preventDefault();
    var regForm = document.querySelector('.hero__text .optin'); 
    validate(regForm);    
    if (isEmailValid) {
      $('.hero__text .btn-submit').addClass('loading');
      $('.hero__text .optin').submit();
    }
  })

  $('.modal .btn-submit').click(function(e){
    e.preventDefault();
    var regForm = document.querySelector('.modal .optin');
    validate(regForm);
    if (isEmailValid) {
      $('.modal .btn-submit').addClass('loading');
      $('.modal .optin').submit();
    }
  })

  $("a[href*='#']").click(function() {
    id=$(this).attr("href")
    $('html, body').animate({
        scrollTop: $(id).offset().top
    }, 1500);
  });

  $(".modal").on('hidden.bs.modal', function(e) {
    $iframe = $(this).find( "iframe" );
    $iframe.empty().remove();
    $(this).find('.embed-responsive').append($iframe);
    $iframe.attr("src", $iframe.attr("src"));
  });

  var fixedBar = document.querySelector('.bar__fixed');
  
  if (fixedBar) {    
    $(window).scroll(function(){
      if ($(window).scrollTop() >= 300) {
        $('.bar__fixed').addClass('fixed');
      }
      else {
        $('.bar__fixed').removeClass('fixed');
      }
    });
  }

});
